<!-- eslint-disable -->
<template>
  <div>
    <strong>AI Reply:</strong>
    <div v-html="typedText"></div>
  </div>
</template>

<script>

export default {
  props: {
    text:{
      type: String,
      required:true,
    },
    typingSpeed:{
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      currentIndex:0,
      typedText:''
    }
  },
  mounted(){
    if(this.text){
      this.typeNextCharacter();
    }
  },
  methods:{
    typeNextCharacter() {
      if (this.currentIndex < this.text.length) {
        if(this.text.charAt(this.currentIndex) === '*'){
          this.typedText += '<br />';
        }else{
          this.typedText += this.text.charAt(this.currentIndex);
        }
        this.currentIndex++;
        setTimeout(this.typeNextCharacter, this.typingSpeed);
      }else{
        this.$emit('completed')
      }
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
